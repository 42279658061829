@import '@styles/functions';

.dropdown {
  z-index: var(--dropdown-menu-z-index);

  .arrow {
    border-bottom-color: inherit;
    border-left-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-top-color: transparent;
    border-width: 0 6px 6px;
    height: 0;
    width: 0;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -6px;
  }

  &[data-popper-placement^='top'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;

    border-top-color: inherit;
    border-width: 6px 6px 0;
    bottom: -6px;
  }

  &[data-popper-placement^='left'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: inherit;
    border-right-color: transparent;

    border-top-color: transparent;
    border-width: 6px 0 6px 6px;
    right: -6px;
  }

  &[data-popper-placement^='right'] > .arrow {
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: inherit;

    border-top-color: transparent;
    border-width: 6px 6px 6px 0;
    left: -6px;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  background: var(--grey80);
  backdrop-filter: blur(20px);
  z-index: 1000;
}

.mobileDropdown {
  z-index: 100000;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--grey90);
  border-radius: 12px 12px 0 0;

  > div {
    width: 100% !important;
  }
}

.handle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4px;
  border-radius: 14px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.thumb {
  width: 96px;
  height: 4px;
  border-radius: 14px;
  background-color: var(--grey50);
}
