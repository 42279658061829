.root {
  display: flex;
  align-items: center;
  justify-content: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: var(--pre-loader-z-index);

  background-color: var(--black);
}
