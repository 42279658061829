@import '@styles/typography';

.root {
  @include caption1;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 22px;
  padding: 0 6px;
  border-radius: 4px;

  width: fit-content;
}

.green {
  color: var(--green1);
  background: var(--green3-t6);
}

.grey {
  color: var(--grey40);
  background-color: var(--grey10-t6);
}

.blue {
  color: var(--blue);
  background-color: var(--blue-t14);
}

.purple {
  color: var(--purple);
  background-color: var(--purple-t14);
}

.yellow {
  color: var(--warning1);
  background-color: var(--yellow-t6);
}

