@import '@styles/typography';

.root {
  @include caption2;

  gap: 8px;
  display: flex;
  align-items: center;

  white-space: nowrap;
  color: var(--grey20);
}
