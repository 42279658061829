@use 'media';

@mixin ellipse-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin truncate-multiline($lineCount) {
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: $lineCount;
  overflow: hidden;
}

@mixin placeholder {
  &::placeholder {
    @content;
  }
}

@mixin centralize {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin common-modal {
  @include centralize;
  border-radius: 12px;
  border: 1px solid var(--grey85);
  background: var(--grey90);
  box-shadow: var(--card-shadow);
}

@mixin clamp-lines($lines) {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  display: -webkit-box;
  line-clamp: $lines;
  overflow: hidden;
  word-wrap: break-word;
}

@mixin page-appear {
  animation: appear 0.6s ease-out;

  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin block-appear {
  animation: appear 0.3s ease-out;

  @keyframes appear {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

@mixin hide-scrollbar {
  // stylelint-disable
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Turn off scrollbar */
  &::-webkit-scrollbar {
    background: transparent;
    border-radius: 0;
    display: none;
    height: 0;
    width: 0;
  }
}

@mixin custom-scrollbar-horizontal {
  /* Turn on custom 4px wide scrollbar */
  &::-webkit-scrollbar {
    background: transparent;
    border-radius: 0;
    height: 3px;
  }

  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  &::-webkit-scrollbar:hover {
    background-color: rgb(232 224 255 / 9%);
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  &::-webkit-scrollbar-thumb:horizontal {
    background-color: rgb(109 110 110 / 100%);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:horizontal:active {
    /* Some darker color when you click it */
    background: rgb(232 224 255 / 61%);
    border-radius: 0;
  }
}

@mixin custom-scrollbar-vertical {
  /* Turn on custom 4px wide scrollbar */
  &::-webkit-scrollbar {
    background: transparent;
    border-radius: 0;
    width: 3px;
  }

  /* hover effect for both scrollbar area, and scrollbar 'thumb' */
  &::-webkit-scrollbar:hover {
    background-color: rgb(232 224 255 / 9%);
  }

  /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
  &::-webkit-scrollbar-thumb:vertical {
    background-color: rgb(109 110 110 / 100%);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb:vertical:active {
    /* Some darker color when you click it */
    background: rgb(232 224 255 / 61%);
    border-radius: 0;
  }
}
