@import '@styles/typography';
@import '@styles/media';
@import '@styles/mixins';

.root {
  @include common-modal;
  max-width: 412px;
  padding: 23px;
  width: 100%;
}

.overlay {
  align-items: center;
}

.image {
  @include centralize;

  .icon {
    animation: 4s rotate linear infinite;
    color: var(--green1);
    height: 64px;
    width: 64px;
  }
}

.title {
  @include title2;
  color: var(--grey10);
  margin: 12px 0 6px;
  text-align: center;
}

.text {
  @include body3;
  color: var(--grey10);
  text-align: center;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
