@import '@styles/typography';

.root {
  @include button;

  outline: none;
  cursor: pointer;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: .2s;

  &[disabled] {
    pointer-events: none;
    color: var(--grey80);
    border: 1px solid var(--grey80);
    background-color: var(--grey90);
  }
}

.iconContainer {
  width: fit-content;

  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 6px;
  }
}

.small {
  @include caption2;
  height: 30px;
}

.medium {
  @include body2;
  height: 34px;
}

.large {
  height: 44px;
}

.primary {
  padding: 0 16px;
  color: var(--black);
  background-color: var(--green1);
  border: 1px solid var(--green1);

  &:focus {
    background-color: var(--green2);
  }

  &:hover {
    color: var(--green1);
    background-color: var(--black);
    border: 1px solid var(--green1);
  }

  &:active {
    color: var(--black);
    background-color: var(--green3);
    border: 1px solid var(--green3);
  }
}

.secondary {
  padding: 0 16px;
  color: var(--grey10);
  background-color: var(--grey90);
  border: 1px solid var(--grey80);

  &:focus {
    border: 1px solid var(--green1);
  }

  &:hover {
    color: var(--green1);
    background-color: var(--black);
    border: 1px solid var(--green1);
  }

  &:active {
    color: var(--green3);
    border: 1px solid var(--green3);
  }
}

.ghost {
  padding: 0 16px;
  color: var(--grey60);
  background-color: var(--grey90);
  border: 1px solid var(--grey90);

  &:hover,
  &:focus {
    color: var(--grey20);
    border: 1px solid var(--grey85);
    background-color: var(--grey85);
  }

  &:active {
    background-color: var(--grey85);
  }
}

.destructive {
  padding: 0 16px;
  color: var(--white);
  background-color: var(--destructive1);
  border: 1px solid var(--destructive1);

  &:focus {
    background-color: var(--error);
    border: 1px solid var(--error);
  }

  &:hover {
    background-color: var(--destructive2);
    border: 1px solid var(--destructive2);
  }

  &:active {
    color: var(--white-t60);
    background-color: var(--destructive3);
    border: 1px solid var(--destructive3);
  }
}

.tertiary1,
.tertiary2,
.tertiary3 {
  background-color: transparent;
  border: 1px solid transparent;

  &[disabled] {
    pointer-events: none;
    color: var(--grey80);
    border: 1px solid transparent;
    background-color: transparent;
  }
}

.text {
  color: currentcolor;
  border: none;
  background: transparent;
  padding: 0;
  display: inline;
  height: unset;
  min-height: unset;

  &:hover {
    filter: brightness(120%);
  }
}

.tertiary1 {
  color: var(--white);

  &:focus {
    color: var(--green2);
  }

  &:hover {
    color: var(--green1);
  }

  &:active {
    color: var(--green3);
  }
}

.tertiary2 {
  color: var(--green1);

  &:focus {
    color: var(--green2);
  }

  &:hover {
    color: var(--green2);
  }

  &:active {
    color: var(--green3);
  }
}

.tertiary3 {
  color: var(--error);

  &:active {
    color: var(--destructive1);
  }
}
