@use 'colors';
@use 'reset';
@use 'media';
@use 'mixins';
@use 'constants';
@use 'typography';
@use 'functions';

*,
*::before,
*::after {
  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

html {
  background-color: var(--grey90);
  color: var(--grey10);
  scroll-behavior: smooth;
  font-family: var(--font-primary);
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  max-width: 100vw;
  min-height: 100vh;
  transform-origin: center;
  transition: transform 0.3s ease;
  width: 100%;
}

.nws-modal-wrapper {
  z-index: var(--wallet-connect-modal-z-index);
}


.Toastify__toast-body {
  @include typography.body2;
}
