.root {
  gap: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.escher {
  height: 24px;
  width: 118px;
}

.ringfenceLogo {
  width: 117px;
  height: 25px;
}
