@import '@styles/typography';
@import '@styles/media';
@import '@styles/mixins';

.root {
  @include common-modal;
  max-width: 412px;
  padding: 23px;
  width: 100%;
}

.overlay {
  align-items: center;
}

.image {
  @include centralize;

  .icon {
    color: var(--green2);
    height: 64px;
    width: 64px;
  }
}

.title {
  @include title2;
  color: var(--grey10);
  margin: 12px 0 6px;
  text-align: center;
}

.text {
  @include body3;
  color: var(--grey10);
  text-align: center;
}

.footer {
  margin-top: 40px;
}

.btn {
  width: 100%;
}

