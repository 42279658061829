:root {
  // Base
  --black: #0C0D0E;
  --white: #FFF;

  // Greyscale
  --grey90: #171717;
  --grey85: #222;
  --grey80: #3D3D3E;
  --grey60: #6D6E6E;
  --grey40: #9E9E9F;
  --grey20: #CECFCF;
  --grey10: #EBEBEB;

  // Green
  --green1: #4ADC8C;
  --green2: #6BE2A1;
  --green3: #2C8956;
  --green4: #0F2117;

  // System
  --error: #ED3E27;
  --warning1: #DCBC4A;
  --warning2: #DC904A;
  --destructive1: #BC200A;
  --destructive2: #961A08;
  --destructive3: #7C1607;

  // Additional
  --blue: #48AAF2;
  --purple: #A5F;

  // Transparent
  --green3-t6: rgb(60 171 110 / 6%);
  --yellow-t6: rgb(220 188 74 / 6%);
  --error-t6: rgb(237 62 39 / 6%);
  --blue-t14: rgb(60 124 171 / 14%);
  --purple-t14: rgb(116 60 171 / 14%);
  --grey10-t6: rgb(235 235 235 / 6%);
  --grey90-t74: rgb(23 23 23 / 74%);
  --white-t60: rgb(255 255 255 / 60%);

  // Gradient
  --grey-overlay: linear-gradient(180deg, rgb(12 13 14 / 0%) 0, var(--black) 100%);
  --grey-overlay-btm: linear-gradient(180deg, rgb(12 13 14 / 0%) 50%, var(--black) 100%);
  --green-gradient: linear-gradient(90deg, var(--green1) 0, #4ADCCA 100%);

  // SHadow
  --card-shadow: 0 4px 24px 0 rgb(0 0 0 / 25%);
  --drop-shadow2: 0 15px 44px 0 rgb(0 0 0 / 55%);
}

