@use 'media';

:root {
  --sidebar-width: 282px;
  --sidebar-opener-height: 54px;
  --avatar-selector-size: 360px;
  --dropdown-menu-z-index: 40;
  --sidebar-z-index: 50;
  --modal-overlay-z-index: 70;
  --modal-container-z-index: 80;
  --wallet-connect-modal-z-index: 90;
  --pre-loader-z-index: 100;

  @include media.tablet-portrait {
    --avatar-selector-size: 500px;
  }
}
