@use 'src/styles/media';
@import '@styles/functions';
@import '@styles/media';
@import '@styles/typography';

.root {
  background-color: #fff;
  border-radius: 12px;
  max-width: 100%;
  min-width: 200px;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 640px;
  z-index: var(--modal-container-z-index);
  opacity: 0;
  transform: translateY(30%);
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.sm {
    width: 412px;
  }

  &.md {
    width: 640px;
  }

  &.lg {
    width: 768px;
  }

  &.xl {
    width: 944px;
  }

  &.xxl {
    width: 1024px;
  }

  &.xxxl {
    max-width: 1280px;
    width: 100%;
  }

  &.auto {
    max-width: 1024px;
    width: 100%;
  }

  &.fullscreen {
    height: 100dvh;
    width: 100vw;
    max-width: unset;
    border-radius: 0;
  }

  &:focus {
    outline: none;
  }
}

.modalOpen {
  overflow: hidden;
}

.overlay {
  align-items: flex-start;
  background: rgb(23 23 23 / 80%);
  inset: 0;
  display: flex;
  justify-content: center;
  opacity: 0;
  overflow-y: auto;
  padding: 8px;
  position: fixed;
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: var(--modal-overlay-z-index);
  width: vw(100);

  @include media.tablet-landscape {
    padding: 60px;
  }
}

.content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.overlay:has(.fullscreen) {
  padding: 0;
}

.contentAfterOpen {
  opacity: 1;
  transform: translateY(0);
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.closeWrapper {
  color: var(--grey30);
  position: absolute;
  transition: color 0.2s;
  z-index: 1;
  right: 4px;
  top: -48px;

  @include media.tablet-landscape {
    right: -32px;
    top: -32px;
  }

  svg {
    width: 24px;
  }
}

@media screen and (max-width: #{media.$breakpoint-sm}px) {
  .overlay {
    align-items: flex-end;
    padding: 0;
  }

  .root {
    border-radius: 6px 6px 0 0;
    max-height: 100vh;
    padding: 48px 0 0;
    transform: translateY(100%);
    transition: all 300ms ease-out;
  }

  .contentAfterOpen {
    transform: translateY(0);
  }

  .contentBeforeClose {
    transform: translateY(100%);
  }
}

.pending {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 24px;
  justify-content: center;
  text-align: center;

  background-color: var(--black-2);
}

.title {
  @include title1;
}

.icon {
  height: 100px;
  width: 100px;
}

.description {
  @include body3;
  margin-top: 16px;
  margin-bottom: 12px;
  color: var(--white);
}


