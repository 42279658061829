@import "@styles/mixins";
@import "@styles/typography";

.root {
  height: 44px;
  padding: 0 12px;
  border-radius: 8px;

  cursor: text;

  gap: 6px;
  display: flex;
  align-items: center;

  background-color: var(--grey90);
  border: 1px solid var(--grey85);

  &.error {
    border-color: var(--error);
  }

  &:focus-within,
  &:hover {
    border-color: var(--green3);
  }

  &.disabled {
    pointer-events: none;
  }

  /* Chrome, Safari, Edge, Opera */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
  }
}

.input {
  @include body3;

  flex: 1;
  border: none;
  outline: none;
  color: var(--grey10);
  background-color: var(--grey90);

  @include placeholder {
    color: var(--grey60);
  }
}
