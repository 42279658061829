@import "@styles/typography";

.tabs {
  @include body2;

  display: flex;
  justify-content: center;
}

.tab {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 6px 6px 0 0;
  transition: .2s;
  user-select: none;
  white-space: nowrap;

  &:hover {
    color: var(--green1);
  }

  &.selected {
    color: var(--green1);
    background-color: var(--grey85);
  }
}
