@import "@styles/typography";

.root {
  @include caption2;
  align-items: flex-start;
  color: var(--error);
  display: flex;
  gap: 4px;
  margin-top: 4px;
}

.icon {
  flex-shrink: 0;
  height: unset;
  width: unset;
}
